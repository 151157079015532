<div class="manage-bookmarks-wrapper container">

    <div class="sidebar">
        <p class="sidebar-heading">Your Apps</p>

        <div class="sidebar-items">
            <a [routerLink]="[bookmark.id]" routerLinkActive="selected" class="sidebar-item" *ngFor="let bookmark of bookmarks">{{ bookmark.name }}</a>
        </div>
    </div>

    <div class="content has-blurred-white-bg">
        <router-outlet></router-outlet>
    </div>

</div>
