<div class="add-bookmark-wrapper container has-blurred-white-bg">
    <h2 class="is-bold">Add App</h2>

    <form #form="ngForm" ngNativeValidate (ngSubmit)="onFormSubmit(form)">
        <div class="form-field">
            <label class="form-label">Name</label>
            <input class="textbox" ngModel #textField="ngModel" name="name" type="text" placeholder="e.g. Wikipedia">
        </div>

        <div class="form-field">
            <label class="form-label">URL</label>
            <input class="textbox" ngModel #textField="ngModel" name="url" type="url" placeholder="e.g. https://wikipedia.com" required>
        </div>

        <div class="btns-container">
            <a routerLink="../" class="btn">Cancel</a>
            <button class="btn align-end">Done</button>
        </div>
    </form>
</div>
