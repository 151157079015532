import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import {Router, RouterOutlet} from '@angular/router';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators'
import {BookmarkService} from './shared/bookmark.service';
import {NotificationService} from './shared/notification.service';
import {Bookmark} from './shared/bookmark.model';

const baseStyles = style({
  // display: 'block',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%'
})

function getWindow (): any {
  console.log("Window = " + window);
  return window;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('routeAnim', [
      transition(':increment', [
        style({
          position: 'relative',
          overflow: 'hidden'
        }),

        query(':enter, :leave', [
          baseStyles
        ], { optional: true }),

        // query(':enter', [
        //   style({ opacity: 0 })
        // ], { optional: true }),

        group([
          query(':leave', [
            animate('200ms ease-in', style({
              opacity: 0,
              transform: 'translateX(-50px)'
            }))
          ], { optional: true }),

          query(':enter', [
            style({
              transform: 'translateX(50px)',
              opacity: 0
            }),
            animate('250ms 120ms ease-out', style({
              opacity: 1,
              transform: 'translateX(0)'
            }))
          ], { optional: true })
        ])

      ]),

      transition(':decrement', [
        style({
          position: 'relative',
          overflow: 'hidden'
        }),

        query(':enter, :leave', [
          baseStyles
        ], { optional: true }),

        // query(':enter', [
        //   style({ opacity: 0 })
        // ], { optional: true }),

        group([
          query(':leave', [
            animate('200ms ease-in', style({
              opacity: 0,
              transform: 'translateX(50px)'
            }))
          ], { optional: true }),

          query(':enter', [
            style({
              transform: 'translateX(-50px)',
              opacity: 0
            }),
            animate('250ms 120ms ease-out', style({
              opacity: 1,
              transform: 'translateX(0)'
            }))
          ], { optional: true })
        ])

      ]),

      transition('* => secondary', [
        style({
          position: 'relative',
          // overflow: 'hidden'
        }),

        query(':enter, :leave', [
          baseStyles
        ], { optional: true }),

        group([
          query(':leave', [
            animate('200ms ease-in', style({
              opacity: 0,
              transform: 'scale(0.8)'
            }))
          ], { optional: true }),

          query(':enter', [
            style({
              transform: 'scale(1.2)',
              opacity: 0
            }),
            animate('250ms 120ms ease-out', style({
              opacity: 1,
              transform: 'scale(1)'
            }))
          ], { optional: true })
        ])
      ]),

      transition('secondary => *', [
        style({
          position: 'relative',
          // overflow: 'hidden'
        }),

        query(':enter, :leave', [
          baseStyles
        ], { optional: true }),

        group([
          query(':leave', [
            animate('200ms ease-in', style({
              opacity: 0,
              transform: 'scale(1.25)'
            }))
          ], { optional: true }),

          query(':enter', [
            style({
              transform: 'scale(0.8)',
              opacity: 0
            }),
            animate('250ms 120ms ease-out', style({
              opacity: 1,
              transform: 'scale(1)'
            }))
          ], { optional: true })
        ])
      ])

    ]),

    trigger('bgAnim', [
      transition(':leave', [
        animate(1000, style({
          opacity: 0
        }))
      ])
    ]),

    trigger('fadeAnim', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(250, style({
          opacity: 1
        }))
      ]),

      transition(':leave', [
        animate(250, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class AppComponent implements OnInit {

  backgrounds: string[] = [
    'https://images.unsplash.com/photo-1434907652076-85f8401482c3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920'
  ]

  loadingBGImage: boolean

  dateTime: Observable<Date>
  win: any;

  constructor(
    private bookmarkService: BookmarkService) { }

  ngOnInit() {
    this.dateTime = timer(0, 1000).pipe(
      map(() => {
        return new Date()
      })
    )
  }

  ngAfterViewInit() {
    console.log("BH: Starting ngAfterViewInit");
    this.win = getWindow();
    console.log("about to call this.win");
    window.addEventListener('message', () => console.log("message received"));
    // @ts-ignore
    if (this.win.vuplex) {
      // @ts-ignore
      this.win.vuplex.addEventListener('message', (event) => this.addBookmark(event))
    } else {
      // @ts-ignore
      this.win.addEventListener('vuplexready', () => this.win.vuplex.addEventListener('message', (event) => this.addBookmark(event)));
    }
  }

  addBookmark(event:any) {
    const json = JSON.parse(event.data);
    console.log('JSON received: ' + event.data);
    if (json.type == 'add_bookmark'){
      const bookmark = new Bookmark(json.title, json.url)
      this.bookmarkService.addBookmark(bookmark)
    }
    if (json.type == 'sync_bookmarks'){
      this.bookmarkService.saveState()
    }
    if (json.type == 'remove_bookmark'){
      this.bookmarkService.deleteBookmarkByUrl(json.url)
    }
    if (json.type == 'searchBookmark'){
      const found = this.bookmarkService.searchBookmark(json.url)
      if (found) {
        console.log('sending foundBookmark');
        this.win.vuplex.postMessage({ type: 'foundBookmark', url: json.url });
      }
    }
  }

  syncBookmarks(event:any) {

  }

  prepareRoute(outlet: RouterOutlet) {
    if (outlet.isActivated) {
      const tab = outlet.activatedRouteData['tab']
      if (!tab) return 'secondary'
      return tab
    }
  }

  async changeBGImage() {
    this.loadingBGImage = true

    const result = await fetch('https://source.unsplash.com/random/1920x1080', {
      method: 'HEAD'
    })

    const alreadyGot = this.backgrounds.includes(result.url)
    if (alreadyGot) {
      // this is the same image as we currently have, so re-run the function
      return this.changeBGImage()
    }

    this.backgrounds.push(result.url)
  }

  onBGImageLoad(imgEvent: Event) {
    // BG image has loaded, now remove the old BG image from the backgrounds array
    const imgElement = imgEvent.target as HTMLImageElement
    const src = imgElement.src
    this.backgrounds = this.backgrounds.filter(b => b === src)

    this.loadingBGImage = false
  }

}
