<div class="edit-bookmark-wrapper">

    <p *ngIf="!bookmark">App not found</p>

    <ng-container *ngIf="bookmark">

        <h2 class="is-bold">{{ bookmark.name }}</h2>

        <form #form="ngForm" ngNativeValidate (ngSubmit)="onFormSubmit(form)">
            <div class="form-field">
                <label class="form-label">Name</label>
                <input class="textbox" [ngModel]="bookmark.name" #textField="ngModel" name="name" type="text"
                    placeholder="e.g. Wikipedia">
            </div>

            <div class="form-field">
                <label class="form-label">URL</label>
                <input class="textbox" [ngModel]="bookmark.url" #textField="ngModel" name="url" type="url"
                    placeholder="e.g. https://wikipedia.com" required>
            </div>

            <div class="btns-container">
                <button type="button" class="btn" (click)="delete()">Delete</button>
                <button class="btn align-end">Done</button>
            </div>
        </form>
    </ng-container>
</div>
