import { Injectable, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { Bookmark } from './bookmark.model';

@Injectable({
  providedIn: 'root'
})
export class BookmarkService implements OnDestroy {

  bookmarks: Bookmark[] = [];

  storageListenSub: Subscription;

  constructor() {
    this.loadState();

    this.storageListenSub = fromEvent(window, 'storage')
      .subscribe((event: StorageEvent) => {
        if (event.key === 'bookmarks') { this.loadState(); }
      });
  }

  ngOnDestroy() {
    if (this.storageListenSub) { this.storageListenSub.unsubscribe(); }
  }

  getBookmarks() {
    return this.bookmarks;
  }

  getBookmark(id: string) {
    return this.bookmarks.find(b => b.id === id);
  }

  addBookmark(bookmark: Bookmark) {
    this.bookmarks.push(bookmark);

    this.saveState();
  }

  searchBookmark(url: string): boolean {
    const first = this.bookmarks.find((obj) => {
      return obj.url.toString() === url;
    });
    console.log('Bookmarks=');
    console.log(this.bookmarks);
    console.log('First=');
    console.log(first);
    if (first) {
      console.log('returning true');
      return true;
    } else {
      console.log('returning false');
      return false;
    }
  }

  updateBookmark(id: string, updatedFields: Partial<Bookmark>) {
    const bookmark = this.getBookmark(id);
    Object.assign(bookmark, updatedFields);

    this.saveState();
  }

  deleteBookmarkByUrl(url: string) {
    const first = this.bookmarks.find((obj) => {
      return obj.url.toString() === url;
    });

    if (first == null) {
      return false;
    } else {
      this.deleteBookmark(first.id);
      return true;
    }
  }

  deleteBookmark(id: string) {
    const bookmarkIndex = this.bookmarks.findIndex(b => b.id === id);
    if (bookmarkIndex == -1) { return; }
    this.bookmarks.splice(bookmarkIndex, 1);
    this.saveState();
  }

  saveState() {
    localStorage.setItem('bookmarks', JSON.stringify(this.bookmarks));
  }

  loadState() {
    try {
      let bookmarksInStorage = JSON.parse(localStorage.getItem('bookmarks'), (key, value) => {
        if (key === 'url') { return new URL(value); }
        return value;
      });

      this.bookmarks.length = 0; // clear the bookmarks array (while keeping the reference)

      if (bookmarksInStorage == null) {
        bookmarksInStorage = this.getDefaultBookmarks();
      }

      this.bookmarks.push(...bookmarksInStorage);
    } catch (e) {
      console.log('There was an error retrieving the bookmarks from localStorage');
      console.log(e);
    }
  }

  getDefaultBookmarks() {
    return [
      {
        "id": "4caaf810-c4b2-41be-b4bf-318e3306137d",
        "url": new URL("https://google.com/"),
        "name": "Google"
      },
      {
        "id": "dab1725d-08d6-4423-8879-b6525ac0ee76",
        "url": new URL("https://xbox.com/play"),
        "name": "XBox Cloud"
      }
    ];
  }
}
