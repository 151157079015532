<div class="tabs">
    <a routerLink="bookmarks" routerLinkActive="selected" class="tab bookmarks-tab">
        <div class="tab-tile">
            <i class="material-icons-outlined">bookmarks</i>
        </div>
    </a>

    <a routerLink="todos" routerLinkActive="selected" class="tab todos-tab">
        <div class="tab-tile">
            <i class="material-icons-outlined">check</i>
        </div>
    </a>

    <a routerLink="notes" routerLinkActive="selected" class="tab notes-tab">
        <div class="tab-tile">
            <i class="material-icons-outlined">notes</i>
        </div>
    </a>
</div>